<template>
  <div class="quotes p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold ">Add Quote</h1>
      </div>
      <form @submit.prevent="submitQuote" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div class="w-full flex">
            <div class="w-full">
              <label class="block text-sm font-medium text-gray-600">
                Customer
              </label>
              <div>{{ customer.customer_name }}</div>
              <div>{{ customer.customer_phone }}</div>
              <div v-if="customer.customer_email">
                {{ customer.customer_email }}
              </div>
              <label
                v-if="
                  customer.billing_business ||
                    customer.billing_phone ||
                    customer.billing_address1 ||
                    customer.billing_address2 ||
                    customer.billing_city ||
                    customer.billing_state ||
                    customer.billing_zip
                "
                class="mt-3 block text-sm font-medium text-gray-600"
              >
                Billing
              </label>
              <div v-if="customer.billing_business">
                {{ customer.billing_business }}
              </div>
              <div v-if="customer.billing_phone">
                {{ customer.billing_phone }}
              </div>
              <div v-if="customer.billing_address1">
                {{ customer.billing_address1 }}
              </div>
              <div v-if="customer.billing_address2">
                {{ customer.billing_address2 }}
              </div>
              <div
                v-if="
                  customer.billing_city ||
                    customer.billing_state ||
                    customer.billing_zip
                "
              >
                {{ customer.billing_city }} {{ customer.billing_state }}
                {{ customer.billing_zip }}
              </div>
            </div>
            <div class="w-full">
              <label class="block text-sm font-medium text-gray-600">
                Location
              </label>
              <div>{{ location?.location_address1 }}</div>
              <div v-if="location.location_address2">
                {{ location.location_address2 }}
              </div>
              <div>
                {{ location?.location_city }}
                {{ location?.location_state }}
                {{ location?.location_zip }}
              </div>
            </div>
          </div>

          <div>
            <label
              for="assigned_to"
              class="block text-sm font-medium text-gray-600"
            >
              Assigned
            </label>
            <div class="mt-1">
              <select
                v-model="quote.assigned_to"
                name="assigned_to"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option> </option>
                <option v-for="user in users" :key="user.id" :value="user.id">{{
                  user.user_name
                }}</option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="quote_notes"
              class="block text-sm font-medium text-gray-600"
            >
              Quote Notes (Internal Use)
            </label>
            <textarea
              v-model="quote.quote_notes"
              name="quote_notes"
              class="mt-1 w-full rounded border border-gray-200 px-4 py-2"
              rows="4"
            ></textarea>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-600">
              Attachments
            </label>
            <input
              @change="selectImages"
              id="quote_attachment_picker"
              accept="image/jpeg,image/png"
              multiple
              type="file"
              class="mt-1 px-4 py-2 w-full border rounded border-gray-200 text-sm"
            />
          </div>

          <div class="flex flex-wrap gap-3">
            <div
              v-for="image in images"
              :key="image"
              class="relative w-36 h-36 object-cover"
            >
              <svg
                @click="removeImage(image.preview)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
              <img
                @click="modalImage(`${image.preview}`)"
                :src="image.preview"
                class="w-full h-full object-cover"
              />
            </div>
          </div>

          <div
            class="mb-8 px-4 pb-4 border rounded space-y-5 relative"
            v-for="(service_line, service_index) in service_lines"
            :key="service_line.id"
          >
            <svg
              @click="removeServiceLine(service_index)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-3 -right-3"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
            <div>
              <label
                for="service_name"
                class="block text-sm font-medium text-gray-600"
              >
                Service Name
              </label>
              <input
                v-model="service_line.service_name"
                disabled
                maxlength="50"
                name="service_name"
                class="mt-1 w-full rounded px-4 py-2"
              />
            </div>

            <div>
              <label
                for="service_description"
                class="block text-sm font-medium text-gray-600"
              >
                Service Description
              </label>
              <textarea
                v-model="service_line.service_description"
                name="service_description"
                class="mt-1 w-full rounded px-4 py-2 border-gray-200"
                rows="3"
              ></textarea>
            </div>

            <div class="w-full flex gap-2">
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Quantity
                </label>
                <input
                  @change="
                    updateSubtotals(
                      service_line.quantity,
                      service_line.override_price,
                      service_index
                    )
                  "
                  type="number"
                  class="w-full px-4 py-2 rounded-l border-gray-200"
                  v-model.number="service_line.quantity"
                />
              </div>
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Unit
                </label>
                <input
                  type="text"
                  disabled
                  class="w-full px-4 py-2 border-gray-200"
                  name="service_unit_name"
                  maxlength="10"
                  v-model="service_line.service_unit_name"
                />
              </div>
              <div class="flex flex-col justify-end items-start relative">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Price
                </label>

                <input
                  @change="
                    updateSubtotals(
                      service_line.quantity,
                      service_line.override_price,
                      service_index
                    )
                  "
                  type="text"
                  class="w-full px-4 py-2 border-gray-200"
                  v-model.number="service_line.override_price"
                />
                <div
                  v-if="
                    service_line.override_price !== service_line.service_price
                  "
                  class="mb-0.5 absolute right-0 select-none line-through px-4 py-2 text-base"
                >
                  {{ service_line.service_price }}
                </div>
              </div>
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Extended Price
                </label>
                <input
                  type="text"
                  disabled
                  class="w-full px-4 py-2 rounded-r border-gray-200"
                  v-model.number="service_line.extended_price"
                />
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-600">
                Service Attachments
              </label>
              <input
                @change="selectImagesService($event, service_index)"
                :id="'service_attachment_picker-' + service_index"
                accept="image/jpeg,image/png"
                multiple
                type="file"
                class="mt-1 px-1 py-2 w-full text-sm"
              />
            </div>

            <div class="flex flex-wrap gap-3  ">
              <div
                v-for="(image, image_index) in service_line.images"
                :key="image"
                class="relative w-34 h-34 object-cover"
              >
                <svg
                  @click="removeImageService(service_index, image_index)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
                <img
                  @click="modalImage(`${image.preview}`)"
                  :src="image.preview"
                  class="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div>
            <label
              for="services"
              class="block text-sm font-medium text-gray-600"
            >
              Services
            </label>
            <div class="flex gap-2">
              <select
                v-model="quote.services"
                name="services"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option> </option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                  >{{ service.service_name }}</option
                >
              </select>
              <div class="flex justify-center">
                <button
                  type="button"
                  @click="selectServiceLine()"
                  class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
                >
                  <div v-if="isPendingAddService" class="spinner"></div>
                  Add Service
                </button>
              </div>
            </div>
          </div>

          <div>
            <label
              for="total_price"
              class="block text-sm font-medium text-gray-600"
            >
              Calculated Price
            </label>
            <input
              v-model="quote.total_price"
              disabled
              name="total_price"
              :class="{ 'line-through': quote.override_price }"
              class="mt-1 w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>

          <div>
            <label
              for="override_price"
              class="block text-sm font-medium text-gray-600"
            >
              Override Price
            </label>
            <input
              @change="
                quote.override_price = formatCurrency(quote.override_price)
              "
              v-model="quote.override_price"
              name="override_price"
              class="mt-1 w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>

          <div class="pt-2 flex justify-center">
            <button
              type="submit"
              class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
            >
              <div v-if="isPending" class="spinner"></div>
              Save
            </button>
          </div>

          <div class="text-center text-red-600 font-semibold">
            {{ customer_err }}
            {{ location_err }}
            {{ users_err }}
            {{ services_err }}
            {{ quote_err }}
            {{ serviceline_err }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { db, timestamp, storage } from "@/firebaseConfig";
import getCollection from "@/composables/getCollection";
import getDocument from "@/composables/getDocument";
import useCollection from "@/composables/useCollection";

export default {
  props: ["customer_id", "location_id", "user"],
  emits: ["loadModalImage"],
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const isPending = ref(false);
    const isPendingAddService = ref(false);
    const images = ref([]);
    const service_lines = ref([]);
    const serviceline_err = ref(null);
    const quote = ref({
      accepted: false,
      locked: false,
      quote_status: "pending",
      total_price: formatCurrency(0.0),
    });

    const business_id = props.user.claims.business_id ?? props.id;

    // read functions
    const { error: customer_err, document: customer } = getDocument(
      `businesses/${business_id}/customers`,
      props.customer_id
    );

    const { error: location_err, document: location } = getDocument(
      `businesses/${business_id}/locations`,
      props.location_id
    );

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    const { error: services_err, documents: services } = getCollection(
      `businesses/${business_id}/services`
    );

    const { error: quote_err, addDoc: quote_addDoc } = useCollection(
      `businesses/${business_id}/quotes`
    );

    // attachment functions
    const selectImages = (event) => {
      const files = event.target.files;

      files.forEach((imageData) => {
        images.value.push({
          image: imageData,
          preview: URL.createObjectURL(imageData),
          name: new Date().getTime() + "_" + imageData.name,
        });
      });

      const picker = document.getElementById("quote_attachment_picker");
      picker.value = "";
    };

    const removeImage = (preview) => {
      if (images.value.length == 1) {
        images.value.pop();
      } else {
        const index = images.value.findIndex((c) => c.preview == preview);
        images.value.splice(index, 1);
      }
    };

    const selectImagesService = (event, index) => {
      const files = event.target.files;
      const array = service_lines.value[index].images ?? [];
      const picker = document.getElementById(
        `service_attachment_picker-${index}`
      );

      files.forEach((file) => {
        array.push({
          image: file,
          preview: URL.createObjectURL(file),
          name: new Date().getTime() + "_" + file.name,
        });
      });

      service_lines.value[index].images = array;
      picker.value = "";
    };

    const removeImageService = (service_index, image_index) => {
      service_lines.value[service_index].images.splice(image_index, 1);
    };

    // button submit functions
    const selectServiceLine = () => {
      isPendingAddService.value = true;
      if (quote.value.services) {
        const selected_service = services.value
          .filter((service) => service.id == quote.value.services)
          .shift();
        const lines = service_lines.value.push({
          ...selected_service,
          quantity: 1,
          override_price: selected_service.service_price,
        });
        updateSubtotals(1, selected_service.service_price, lines - 1);
      }
      isPendingAddService.value = false;
    };

    const removeServiceLine = (service_index) => {
      updateSubtotals(0, 0, service_index);
      service_lines.value.splice(service_index, 1);
    };

    const submitQuote = async () => {
      isPending.value = true;

      const current = await db
        .collection(`businesses/${business_id}/quotes`)
        .orderBy("sequence_number", "desc")
        .limit(1)
        .get();
      const sequence_number = current.empty
        ? 11111
        : current.docs[0].data().sequence_number + 1;
      const quote_doc = await quote_addDoc({
        createdAt: timestamp(),
        createdBy: props.user.uid,
        sequence_number: sequence_number,
        customer_id: customer.value.id,
        location_id: location.value.id,
        assigned_to: quote.value.assigned_to ?? "",
        quote_notes: quote.value.quote_notes ?? "",
        quote_status: quote.value.assigned_to ? "assigned" : "pending",
        quote_assigned: quote.value.assigned_to ? timestamp() : "",
        accepted: quote.value.accepted,
        locked: quote.value.locked,
        total_price: quote.value.total_price,
        override_price: quote.value.override_price ?? "",
        location: location.value,
        customer: customer.value,
      });

      const quote_images = images.value.map(async (image) => {
        const storageRef = storage.ref(
          `${business_id}/quotes/${quote_doc.id}/${image.name}`
        );
        await storageRef.put(image.image);
        const url = await storage
          .ref(business_id)
          .child("quotes")
          .child(quote_doc.id)
          .child(image.name)
          .getDownloadURL();

        return { url: url, filename: image.name };
      });

      const downloadable_images = await Promise.all(quote_images);

      await db.doc(`businesses/${business_id}/quotes/${quote_doc.id}`).update({
        attachments: downloadable_images,
      });

      service_lines.value.forEach(async (service_line) => {
        let imagesURL = [];
        if (service_line?.images) {
          const images_snapshot = service_line.images.map(async (image) => {
            const storageRef = storage.ref(
              `${business_id}/quotes/${quote_doc.id}/services/${service_line.id}/${image.name}`
            );
            await storageRef.put(image.image);

            const url = await storage
              .ref(business_id)
              .child("quotes")
              .child(quote_doc.id)
              .child("services")
              .child(service_line.id)
              .child(image.name)
              .getDownloadURL();

            return { url: url, filename: image.name };
          });

          imagesURL = await Promise.all(images_snapshot);
        }

        try {
          await db
            .collection(
              `businesses/${business_id}/quotes/${quote_doc.id}/service_lines`
            )
            .add({
              createdAt: timestamp(),
              createdBy: props.user.uid,
              service_id: service_line.id,
              service_name: service_line.service_name,
              service_description: service_line.service_description,
              service_price: service_line.service_price,
              service_unit_name: service_line.service_unit_name,
              quantity: service_line.quantity,
              extended_price: service_line.extended_price,
              override_price: service_line.override_price,
              images: imagesURL,
            });
        } catch (err) {
          console.error(err.message);
          serviceline_err.value = "Could not save the service data";
        }
      });
      isPending.value = false;
      if (!quote_err.value && !serviceline_err.value) {
        router.push("/quotes");
      }
    };

    // utility functions
    const updateSubtotals = (qty, price, index) => {
      // proper rounding
      price = Math.round((Number(price) + Number.EPSILON) * 100) / 100 || 0;

      // update line price format
      service_lines.value[index].override_price = formatCurrency(price);

      // update line total
      service_lines.value[index].extended_price = Number(qty * price).toFixed(
        2
      );

      // update quote total
      quote.value.total_price = formatCurrency(
        service_lines.value
          .map((s) => s.extended_price)
          .reduce((prev, next) => Number(prev) + Number(next))
      );
    };

    const modalImage = (data) => {
      context.emit("loadModalImage", data);
    };

    return {
      route,
      router,
      isPending,
      isPendingAddService,
      images,
      service_lines,
      quote,
      customer_err,
      customer,
      location_err,
      location,
      users_err,
      users,
      services_err,
      services,
      quote_err,
      serviceline_err,
      selectImages,
      removeImage,
      selectImagesService,
      removeImageService,
      selectServiceLine,
      removeServiceLine,
      submitQuote,
      updateSubtotals,
      formatCurrency,
      modalImage,
    };
  },
};

function formatCurrency(val) {
  val = val.toString().replace(/[^0-9.]/g, "");
  if (val.trim() == "") {
    return null;
  }
  return Number(val).toFixed(2);
}
</script>
